import { useEffect, useState, useRef } from 'react'
import { RateQuestion } from './components/question_types/RateQuestion'
import { Input } from './components/question_types/Input'
import { Checkbox } from './components/question_types/Checkbox'
import { useTranslation } from 'react-i18next'
import { NewAreaRate } from 'ui/pages/poll/components/question_types/NewAreaRate'
import { YesNo } from 'ui/pages/poll/components/question_types/YesNo'
import { POPUP_PREV_SPECIAL_QUESTIONS_IDS, POPUP_SPECIAL_QUESTIONS_IDS } from 'ui/utils/constants'

const Question = ({
  questions,
  specialData,
  onSectionCompleted,
  onPreviousSection,
  lastQuestionId,
  onAnswerChange,
  answers,
  saveForm,
  isFirstSection,
  questionsIds,
  formId,
  hashContinue,
  isLastSection,
  store,
  countryId,
  multiLocationSection
}) => {
  const { t } = useTranslation('global')
  const [questionId, setQuestionId] = useState(lastQuestionId)
  const [sectionCompleted, setSectionCompleted] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [mandatoryComment, setMandatoryComment] = useState(false)
  const [isFirstStep, setIsFirstStep] = useState(true)
  const [isLastStep, setIsLastStep] = useState(false)
  const [progress, setProgress] = useState(hashContinue ? 45 : 0)
  const questionTitleRef = useRef(null)
  const inactivityTimerRef = useRef(null)

  // Hide section question for multi location store only when the section is man
  const hideSectionQuestion = multiLocationSection === '2'

  useEffect(() => {
    setQuestionId(lastQuestionId)
  }, [lastQuestionId])

  // Add focus to question title when questionId changes (navigation between questions)
  useEffect(() => {
    if (questionTitleRef.current) {
      questionTitleRef.current.focus()
    }
  }, [questionId])

  const nextQuestion = (event) => {
    event.preventDefault()
    const savedAnswer = answers.find(answer => answer.question_id === questions[questionId].id)
    const invalidFirstCustomer = questions[questionId].id === 644 && ((!savedAnswer.value && !savedAnswer.comment) || (savedAnswer.value === 1 && !savedAnswer.comment) || (!savedAnswer.value && savedAnswer.comment))
    const invalidAnswer = savedAnswer && (savedAnswer.value === undefined || savedAnswer.value === null)

    /// Invalid special answer -- only for popup store customer form
    let invalidSpecialAnswer
    if (savedAnswer) {
      invalidSpecialAnswer = POPUP_PREV_SPECIAL_QUESTIONS_IDS.includes(savedAnswer.question_id) && (specialData && POPUP_SPECIAL_QUESTIONS_IDS.includes(specialData.id) && savedAnswer.value !== 0) && (answers.find(answer => POPUP_SPECIAL_QUESTIONS_IDS.includes(answer.question_id)).value === null || answers.find(answer => POPUP_SPECIAL_QUESTIONS_IDS.includes(answer.question_id)).value === undefined)
    }

    if (!isLastStep) {
      // Set mandatory question when field is true and only for rate questions
      if (questions[questionId].mandatory &&
        invalidFirstCustomer) {
        if (!savedAnswer.value) {
          setIsEmpty(true)
        } else if (!savedAnswer.comment) {
          setIsEmpty(false)
          setMandatoryComment(true)
        }
      } else if ((questions[questionId].mandatory && invalidAnswer) || invalidSpecialAnswer) {
        setIsEmpty(true)
      } else {
        setIsEmpty(false)
        setMandatoryComment(false)
        if (questionId < questions.length - 1) {
          /// Case the section question should be hidden, then skip it
          if (hideSectionQuestion && questions[questionId + 1].id === 640) {
            /// Save answer to selected man section (id 593)
            onAnswerChange(questions[questionId + 1].id, [593], null)
            setQuestionId(questionId + 2)
          } else {
            setQuestionId(questionId + 1)
          }
        } else {
          setSectionCompleted(true)
        }
        handleProgress(questionId, false)

        // After clicking on next, if it was the first step, now it is not anymore
        if (isFirstStep && isFirstSection) {
          setIsFirstStep(false)
        } else if (isLastSection && questionId + 1 === questions.length - 1) {
          setIsLastStep(true)
        }
      }
    } else {
      if (questions[questionId].mandatory && questions[questionId].question_type === 2 && (!savedAnswer.value && !savedAnswer.comment)) {
        setIsEmpty(true)
      } else {
        saveForm(answers)
        onSectionCompleted()
      }
    }
  }

  const previousQuestion = (event) => {
    event.preventDefault()
    setIsEmpty(false)

    let newQuestionId = questionId
    if (questionId > 0) {
      /// Check if the previous question is the section question and it should be hidden
      if (hideSectionQuestion && questions[questionId - 1].id === 640) {
        newQuestionId = questionId - 2
      } else {
        newQuestionId = questionId - 1
      }
      setQuestionId(newQuestionId)
    } else {
      onPreviousSection()
    }
    handleProgress(questionId, true)

    // Check if when we set questionId it is 0, then check if we need to reset first step
    if (isFirstSection && newQuestionId === 0) {
      setIsFirstStep(true)
    }

    if (isLastStep) setIsLastStep(false)
  }

  const finishForm = (event) => {
    event.preventDefault()
    setIsLastStep(true)
    saveForm(answers)
  }

  const handleProgress = (questionId, isBack) => {
    // Questions ids are the ids of all the questions (except the area questions) and they are in order shown
    const totalProgress = questionsIds.length - 1

    const actualQuestion = questionsIds.indexOf(questionsIds.find(questionsId => questionsId === questions[questionId].id))
    const actualPosition = isBack ? actualQuestion - 1 : actualQuestion + 1

    // For customer form (id 20 in pro and id 34 in dev), set hardcoded progress
    if (formId === 20 || formId === 34) {
      const customProgress = hashContinue || hideSectionQuestion ? [45, 65, 80, 90, 95, 100] : [0, 45, 65, 80, 90, 95, 100]
      const progress = customProgress[actualPosition]
      setProgress(progress)
    } else {
      // Progress computed as the actual id, with which we can access the index in questions ids and know the position of actual question
      const progress = actualPosition / totalProgress * 100
      setProgress(progress)
    }
  }

  useEffect(() => {
    if (sectionCompleted) {
      onSectionCompleted()
      setQuestionId(0)
      setSectionCompleted(false)
    }
  }, [sectionCompleted, onSectionCompleted])

  useEffect(() => {
    document.documentElement.style.setProperty('--progressWidth', `${progress.toString()}%`)
  }, [progress])

  // Reset inactivity timer when user interacts with the screen
  const resetInactivityTimer = () => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current)
    }

    inactivityTimerRef.current = setTimeout(() => {
      // Reload the application after 1 minute of inactivity
      window.location.reload()
    }, 60000) // 60000 ms = 1 minute
  }

  // Set up inactivity timer and event listeners
  useEffect(() => {
    // Initialize the timer when component mounts
    resetInactivityTimer()

    // Add event listeners for user interaction
    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart']

    const handleUserActivity = () => {
      resetInactivityTimer()
    }

    // Register all event listeners
    events.forEach(event => {
      document.addEventListener(event, handleUserActivity)
    })

    // Clean up event listeners and timer when component unmounts
    return () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current)
      }

      events.forEach(event => {
        document.removeEventListener(event, handleUserActivity)
      })
    }
  }, [])

  return (
    <section key={questions[questionId].id} className='w-full flex-initial flex-grow flex flex-col page-transition'>
      <form className='flex-initial flex-grow flex flex-col'>
        <div
          className='flex-grow flex flex-col'
        >
          {/* Question title wrapper for accessibility - allows focus when navigating */}
          <div className={`relative mb-10 small:mb-5 flex flex-col items-center ${questions[questionId].question_type === 2 || questions[questionId].question_type === 12 ? 'mb-0' : ''}`}>
            <h2
              ref={questionTitleRef}
              className='question-text'
              tabIndex='-1'
              aria-live='polite'
            >
              {questions[questionId].text}
            </h2>
          </div>

          {questions[questionId].question_type === 2 &&
            <RateQuestion
              onAnswerChange={onAnswerChange}
              savedAnswers={answers}
              question={questions[questionId]}
              key={questions[questionId]}
              hideTitle
            />}
          {questions[questionId].question_type === 12 && (questions[questionId].id === 1001 || questions[questionId].id === 706) &&
            <NewAreaRate
              question={questions[questionId]}
              areasData={specialData}
              onAnswerChange={onAnswerChange}
              savedAnswers={answers}
              key={questions[questionId]}
              countryId={countryId}
              hideTitle
            />}
          {questions[questionId].question_type === 0 &&
            <Input
              onAnswerChange={onAnswerChange}
              savedAnswers={answers}
              question={questions[questionId]}
              key={questions[questionId]}
              hideTitle
            />}
          {(questions[questionId].question_type === 7 && !hideSectionQuestion) &&
            <Checkbox
              onAnswerChange={onAnswerChange}
              savedAnswers={answers}
              question={questions[questionId]}
              key={questions[questionId]}
              hideTitle
              store={store}
              multiLocationSection={multiLocationSection}
            />}
          {
            // Yes no type
            questions[questionId].question_type === 4 &&
              <YesNo
                onAnswerChange={onAnswerChange}
                savedAnswers={answers}
                question={questions[questionId]}
                secondaryQuestion={specialData}
                hideTitle
              />
          }
        </div>

        <div className='flex flex-col mb-10 small:mb-2 gap-2 mt-4'>
          {(isEmpty || mandatoryComment) &&
            <p className='text-rose-600 text-center text-xs italic font-normal mb-2'>
              {isEmpty ? t('fill_question') : t('fill_comment')}
            </p>}
          <button type='submit' className={`continue-btn ${isFirstStep ? 'mb-12' : ''}`} onClick={nextQuestion}>
            {isLastStep ? t('finish') : t('continue')}
          </button>
          {!isFirstStep && <button className='prev-btn' onClick={previousQuestion}>{t('back')}</button>}
          {!isFirstStep && !isLastStep && <button type='submit' onClick={finishForm} className='finish-btn'>{t('finish_here')}</button>}
        </div>
      </form>
    </section>
  )
}

export default Question
