import { useEffect, useState } from 'react'
import * as Constants from 'ui/utils/constants'
import { areas } from 'ui/utils/ratingAreas'
import { getLeapLocale } from 'ui/utils/utils'

export function useQuestionData ({ store, hashContinue, locale, domainUrl, apiUrl, type }) {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [specialData, setSpecialData] = useState([])
  const [finishData, setFinishData] = useState([])
  const [hasMultiLocations, setHasMultiLocations] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const leapLocale = getLeapLocale(locale)
    const version = '2'

    const url = `${Constants.DEBUG ? Constants.DOMAIN_URL_DEV : domainUrl}${apiUrl}`

    // Query params
    const paramLocale = `locale=${leapLocale}`
    const paramVersion = `&version=${version}`
    const paramType = type ? `&type=${type}` : ''
    const paramStore = `&store=${store}`

    fetch(`${url}get_form?${paramLocale}${paramVersion}${paramStore}${paramType}`)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw new Error('Failed to load questions')
      })
      .then((data) => {
        // Separate sections that are for areas question in another list
        let auxAreasData = []
        let finishData = []

        if (data != null) {
          document.title = data.text
        }
        if (data.version === 2) {
          // For customer, group together section questions

          for (let i = 0; i < data.form_sections.length; i++) {
            const section = data.form_sections[i]

            if (areas[section.id] !== undefined) {
              auxAreasData.push(data.form_sections.splice(i, 1)[0])
              i--
            }
          }

          // Special case for area with id 162 because is empty (id 123 in dev form)
          /// TODO: improve this to not be hardcoded!!
          auxAreasData = auxAreasData.filter(areas => areas.id !== 162 && areas.id !== 123)

          setSpecialData(auxAreasData)
          data.form_sections.forEach((section) => {
            // Add special sections to question 1001 or question 706 in dev
            const areaQuestion = section.questions.find((question) => question.id === 1001 || question.id === 706)

            // Filter last questions to get goodbye text and delete them from original data
            finishData = section.questions.filter(question => question.id === 1002 || question.id === 1003 || question.id === 715 || question.id === 716)
            finishData.forEach(finishItem => section.questions.splice(section.questions.findIndex(e => e.id === finishItem.id), 1))
            if (areaQuestion) {
              areaQuestion.special_questions = auxAreasData
            }
            if (finishData) {
              setFinishData(finishData)
            }

            // Remove first question if form has continue hash
            if (hashContinue !== null) section.questions.splice(section.questions.findIndex(question => question.id === Constants.FIRST_QUESTION_ID || Constants.POPUP_FIRST_QUESTION_IDS.includes(question.id)), 1)
          })
        } else if (data.form_type === 2 && (data.version === 3 || data.version === 4)) {
          /// Special customer form for china, remove question 1319 because it is activated on question 1318 when clicking on yes
          /// Special customer form for switzerland, remove question 1439 because it is activated on question 1439 when clicking on yes
          for (let i = 0; i < data.form_sections.length; i++) {
            const section = data.form_sections[i]
            for (let j = 0; j < section.questions.length; j++) {
              const question = section.questions[j]
              if (Constants.POPUP_SPECIAL_QUESTIONS_IDS.includes(question.id)) {
                setSpecialData(question)
                data.form_sections[i].questions.splice(j, 1)

                /// Order questions by order
                data.form_sections[i].questions.sort((a, b) => a.order - b.order)
                break
              }
            }

            if (hashContinue !== null) section.questions.splice(section.questions.findIndex(question => question.id === Constants.FIRST_QUESTION_ID || Constants.POPUP_FIRST_QUESTION_IDS.includes(question.id)), 1)
          }
        }

        /// Check if form has multi locations
        if (data.multi_location && data.multi_location.length > 0) {
          setHasMultiLocations(true)
        }

        setData(data)
        setIsLoading(false)
      })

    // TODO uncomment this when app is release
    // history.pushState(null, "", `${location.href.split("?")[0]}#`);
  }, [store, hashContinue, locale, domainUrl])

  return { data, isLoading, specialData, finishData, hasMultiLocations }
}
