import { useEffect, useRef, useState } from 'react'
import { YesNoItem } from 'ui/pages/poll/components/question_types/YesNoItem'
import { Checkbox } from 'ui/pages/poll/components/question_types/Checkbox'
import { areas } from 'ui/utils/ratingAreas'
import { isMobile } from 'ui/utils/utils'
import * as Constants from 'ui/utils/constants'

export function YesNo ({ onAnswerChange, savedAnswers, question, secondaryQuestion }) {
  const savedAnswer = savedAnswers.find(answer => answer.question_id === question.id)
  const savedSecondaryAnswer = savedAnswers.find(answer => answer.question_id === secondaryQuestion.id)
  const [showInput, setShowInput] = useState(savedAnswer.comment !== null || savedAnswer.comment === '')
  const [comment, setComment] = useState(savedAnswer.comment)
  const [value, setValue] = useState(savedAnswer.value)
  const [isMobileDevice, setIsMobile] = useState(false)


  const textareaRef = useRef(null)

  useEffect(() => {
    const checkIsMobile = isMobile()
    setIsMobile(checkIsMobile)
  }, [])

  const manageValues = (newValue) => {
    if (value !== newValue) setValue(newValue ?? null)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }

  const handleUnfocus = () => {
    if(!comment) {
      setShowInput(false)
    }
  }

  const handleClickOutside = (e) => {
    if(textareaRef.current && !textareaRef.current.contains(e.target)) {
      handleUnfocus()
    }
  }

  useEffect(() => {
    onAnswerChange(question.id, value, comment)

    // In case we are in question with second question when clikcing on yes; if we click on no, remove saved answer value
    if (Constants.POPUP_PREV_SPECIAL_QUESTIONS_IDS.includes(question.id) && value === 0 && savedSecondaryAnswer) {
      onAnswerChange(secondaryQuestion.id, null, null)
    }
  }, [value, comment])

  return (
    // YES is 1, NO is 0
    <div className="flex-grow">
      <YesNoItem onSelected={manageValues}
                 initialState={savedAnswer.value}
      />
      {question.has_tell_me_more && <div className="flex align-center justify-center mt-8">
        {showInput
          ? <textarea
            ref={textareaRef}
            className="tellMore"
            value={comment ?? ''}
            id={question.id}
            rows={isMobileDevice ? 3 : 7}
            placeholder={question.description}
            onChange={handleCommentChange}
            onBlur={handleUnfocus}
            aria-labelledby={`question-label-${question.id}`}
          />
          : <button aria-expanded={showInput} className="whiteBtn" onClick={() => setShowInput(true)}>{question.description}</button>}
      </div>}
      {(Constants.POPUP_PREV_SPECIAL_QUESTIONS_IDS.includes(question.id) && value === 1) &&
      <div className="move-down-transition">
        <Checkbox onAnswerChange={onAnswerChange}
                  savedAnswers={savedAnswers}
                  question={secondaryQuestion}
                  key={secondaryQuestion.id}
                  isSmall={true}
        />
      </div>
      }
    </div>
  )
}
