import parse from 'html-react-parser'

const StartDescription = (description) => {
  const { props } = description
  return (
    <div 
      className='flex-initial flex-grow flex flex-col items-center mt-12 tablet:mt-2 start-title start-transition second'
      tabIndex="0"
      aria-live="polite"
      role="region"
      aria-label="Welcome message"
    >
      {parse(props)}
    </div>
  )
}

export default StartDescription
