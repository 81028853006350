import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'ui/utils/utils'

export function Input ({ onAnswerChange, savedAnswers, question }) {
  const { t } = useTranslation('global')
  const [answer, setAnswer] = useState('');
  const [isMobileDevice, setIsMobileDevice] = useState(false)

  useEffect(() => {
    const checkMobile = isMobile()
    setIsMobileDevice(checkMobile)
  }, [])

  useEffect(() => {
    const savedAnswer = savedAnswers.find(answer => answer.question_id === question.id);
    if (savedAnswer) {
      setAnswer(savedAnswer.value);
    } else {
      setAnswer('');
    }
  }, [savedAnswers, question.id]);

  const handleChange =(event) => {
    const newAnswer = event.target.value
    setAnswer(newAnswer)
    onAnswerChange(question.id, newAnswer, null)
  }
  
  return (
    <div className='mb-4'>
      <textarea
        className="tellMore mt-12"
        id={question.id}
        rows={isMobileDevice ? 3 : 8}
        placeholder={question.description}
        value={answer ?? ''}
        onChange={handleChange}
        aria-labelledby={`label-${question.id}`}
      />
    </div>
  )
}
