export function StarOutlined ({ isMobileDevice, ariaLabel }) {
  return (
    <span
      aria-label={ariaLabel}
      aria-hidden='false'
      role='img'
      className='inline-flex'
      style={{
        width: `${isMobileDevice ? '3.125rem' : '4.375rem'}`,
        height: `${isMobileDevice ? '3.125rem' : '4.375rem'}`
      }}
    >
      <svg
        viewBox='0 0 47 45'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.1344 42.6316L23.9271 34.1941L37.6 42.6316L34.075 26.7928L44.9437 16.727L30.1094 15.5246L23.9271 1.33224L17.7719 15.5246L2.05625 16.727L13.8062 26.7928L10.1344 42.6316ZM9.39999 45L8.54544 43.3019L12.5552 27.431L0 16.9811V15.5246L16.9101 14.1402L23.0728 0H24.9175L30.9442 14.1402L47 15.5246V16.9811L35.2991 27.431L39.309 43.3019L38.4545 45L23.9271 35.6276L9.39999 45Z'
          fill='black' fillOpacity='0.6'
        />
      </svg>
    </span>
  )
}
