import { useEffect, useRef, useState } from "react";
import { areas } from "ui/utils/ratingAreas";
import { useTranslation } from "react-i18next";
import { isMobile } from "ui/utils/utils";
import RatingComponent from "./Rating";
import { StarFilled } from "../../../../common/StarFilled";
import { StarOutlined } from "../../../../common/StarOutlined";
import Legend from "../../../../common/Legend";

export function RateQuestion({
  onAnswerChange,
  savedAnswers,
  question,
  areaIcon,
  hideTitle,
}) {
  const { t } = useTranslation("global");
  const savedAnswer = savedAnswers.find(
    (answer) => answer.question_id === question.id
  );

  const [isMobileDevice, setIsMobile] = useState(false);
  const [rating, setRating] = useState(savedAnswer.value);
  const [comment, setComment] = useState(savedAnswer.comment);
  const [showInput, setShowInput] = useState(
    savedAnswer.comment !== null || savedAnswer.comment === ""
  );
  const textareaRef = useRef(null);
  const starsRef = useRef([]);
  const [usingKeyboard, setUsingKeyboard] = useState(false);

  useEffect(() => {
    const checkIsMobile = isMobile();
    setIsMobile(checkIsMobile);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Tab" || e.key.includes("Arrow")) {
        setUsingKeyboard(true);
      }
    };

    const handleMouseOrTouch = () => {
      setUsingKeyboard(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleMouseOrTouch);
    document.addEventListener("touchstart", handleMouseOrTouch);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleMouseOrTouch);
      document.removeEventListener("touchstart", handleMouseOrTouch);
    };
  });

  useEffect(() => {
    if (areaIcon) {
      for (const area in areas) {
        onAnswerChange(area, null, null);
      }
    }
    onAnswerChange(question.id, rating, comment);
  }, [rating, comment]);

  const handleRating = (newRate) => {
    if (newRate === rating) {
      setRating(null);
    } else {
      setRating(newRate);
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleUnfocus = () => {
    if (!comment) {
      setShowInput(false);
    }
  };

  const handleClickOutside = (e) => {
    if (textareaRef.current && !textareaRef.current.contains(e.target)) {
      handleUnfocus();
    }
  };

  const handleKeyDown = (event, starValue) => {
    let newRating = rating;
    switch (event.key) {
      case " ":
      case "Enter":
        event.preventDefault();
        newRating = starValue;
        break;
      case "ArrowRight":
      case "ArrowUp":
        event.preventDefault();
        newRating = Math.min(5, (rating || 0) + 1);
        break;
      case "ArrowLeft":
      case "ArrowDown":
        event.preventDefault();
        newRating = Math.max(1, (rating || 2) - 1);
        break;
      case "Home":
        event.preventDefault();
        newRating = 1;
        break;
      case "End":
        event.preventDefault();
        newRating = 5;
        break;
      default:
        return;
    }
    starsRef.current[newRating - 1]?.focus();
    if (newRating === rating) {
      setRating(null);
    } else {
      setRating(newRating);
    }
  };

  return (
    <>
      <div className='relative mb-10 small:mb-5 flex flex-col items-center'>
        <p
          className='legend-text'
          tabIndex='0'
          aria-live='polite'
          role='status'
        >
          {t("star_legend")}
        </p>
        {/*<Legend breakLegend /> */}
      </div>
      <div
        className='flex w-full rating items-center mb-5'
        role='radiogroup'
        aria-label='Rating'
      >
        {[1, 2, 3, 4, 5].map((value, i) => (
          <button
            key={value}
            ref={(el) => (starsRef.current[i] = el)}
            type='button'
            role='radio'
            aria-checked={rating === value}
            aria-label={`${value} ${t("stars")}`}
            title={`${value} ${t("stars")}`}
            tabIndex={0}
            onClick={() => handleRating(value)}
            onKeyDown={(e) => handleKeyDown(e, value)}
            className={`star ${
              rating >= value ? "filled" : "outlined"
            } star-button ${usingKeyboard ? "" : "focus-hide"}`}
          >
            {rating >= value ? (
              <StarFilled isMobileDevice aria-hidden='true' />
            ) : (
              <StarOutlined isMobileDevice aria-hidden='true' />
            )}
          </button>
        ))}
      </div>

      <div className='flex align-center justify-center'>
        {showInput ? (
          <textarea
            ref={textareaRef}
            className='tellMore'
            value={comment ?? ""}
            // id={`comment-${question.id}`}
            rows={isMobileDevice ? 3 : 7}
            placeholder={question.description}
            onChange={handleCommentChange}
            onBlur={handleUnfocus}
          />
        ) : (
          <button
            type='button'
            className='whiteBtn'
            onClick={() => setShowInput(true)}
          >
            {question.description}
          </button>
        )}
      </div>
    </>
  );
}
