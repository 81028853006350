export function StarFilled({ isSmall, isMobileDevice }) {
  const size = isMobileDevice
    ? isSmall ? '1rem' : '3.125rem'
    : isSmall ? '1rem' : '4.375rem'

  return (
    <span
      className="inline-flex"
      style={{ width: size, height: size }}
    >
      <svg
        viewBox="0 0 47 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path
          d="M9.39999 45L8.54544 43.3019L12.5552 27.431L0 16.9811V15.5246L16.9101 14.1402L23.0728 0H24.9175L30.9442 14.1402L47 15.5246V16.9811L35.2991 27.431L39.309 43.3019L38.4545 45L23.9271 35.6276L9.39999 45Z"
          fill="#1C1B1F"
        />
      </svg>
    </span>
  )
}