import { useRef, useState } from "react";
import Section from "./Section";
import Start from "../start/Start";
import Logo from "../../common/Logo";
import { useQuestionData } from "ui/hooks/useQuestionData";
import { useAnswersData } from "ui/hooks/useAnswersData";
import * as Constants from "ui/utils/constants";
import { TailSpin } from "react-loader-spinner";
import ReactModal from "react-modal";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { Finish } from "ui/pages/finish/Finish";
import axios from "axios";
import { Error } from "ui/pages/error/Error";
import { OpacityLoader } from "ui/common/OpacityLoader";
import { LanguageSelector } from "ui/pages/poll/components/LanguageSelector";
import AccessibilityMenu from "ui/pages/poll/components/AccessibilityMenu";

export function Poll({
  store,
  font,
  e,
  section,
  area,
  till,
  hashContinue,
  locale,
  supportedLangs,
  onSelectedLanguage,
  resetLocale,
  domainUrl,
  apiUrl,
  type,
  eid,
}) {
  const { t } = useTranslation("global");
  const [isStarted, setIsStarted] = useState(false);
  const { data, isLoading, specialData, finishData, hasMultiLocations } =
    useQuestionData({ store, hashContinue, locale, domainUrl, apiUrl, type });
  const [answers, setAnswers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [error, setError] = useState(false);
  const [finishFromModal, setIsFinishFromModal] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [exitLanguageSelector, setExitLanguageSelector] = useState(true);

  useAnswersData({ data, isLoading, setAnswers, specialData });

  function changeStart() {
    if (!data.legal_text) handleAcceptModal();
    setIsModalOpen(true);
  }

  function handleAnswerChange(questionId, answer, comment) {
    setAnswers((previousAnswers) =>
      previousAnswers.map((prevAnswer) => {
        if (prevAnswer.question_id === questionId) {
          if (Array.isArray(answer) && answer.length === 0)
            return { ...prevAnswer, value: null, comment };
          return { ...prevAnswer, value: answer, comment };
        }
        return prevAnswer;
      })
    );
  }

  const saveForm = (answers) => {
    const jsonData = {};
    const finalData = {};
    finalData.type = "cx_customer_feedback_form";
    finalData.id = data.id;
    finalData.store_code = store;
    finalData.e = e;
    finalData.eid = eid;
    if (section != null) finalData.section = section;
    if (area != null) finalData.area = area;
    if (till != null) finalData.till = till;
    if (hashContinue) finalData.continue = hashContinue;
    finalData.answers = answers.map((answer) =>
      answer.value
        ? {
            ...answer,
            value: `${
              Array.isArray(answer.value) && answer.value
                ? `[${answer.value}]`
                : answer.value.toString()
            }`,
          }
        : answer
    );
    jsonData.data = finalData;
    const baseURL = `${
      Constants.DEBUG ? Constants.DOMAIN_URL_DEV : domainUrl
    }${apiUrl}save_form`;
    axios
      .post(baseURL, jsonData)
      .then(() => {
        setIsFinish(true);
        setIsBusy(false);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      });
    setIsBusy(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsFinish(true);
    setIsFinishFromModal(true);
  };

  const handleAcceptModal = () => {
    setIsModalOpen(false);
    setIsStarted(true);
  };

  const restartForm = () => {
    setIsStarted(false);
    setIsFinish(false);
    setIsFinishFromModal(false);
    emptyAnswers();
    resetLocale();
  };

  const emptyAnswers = () => {
    answers.forEach(() => {
      setAnswers((previousAnswers) =>
        previousAnswers.map((prevAnswer) => {
          return { ...prevAnswer, value: null, comment: null };
        })
      );
    });
  };

  const openLanguageSelector = () => {
    setExitLanguageSelector(false);
    setShowLanguageSelector(true);
  };

  const closeLanguageSelector = (selectedLang) => {
    setExitLanguageSelector(true);
    onSelectedLanguage(selectedLang);
    setTimeout(() => {
      setShowLanguageSelector(false);
    }, 500); // Delay must be the same as the transition duration
  };

  return (
    <>
      {/* Accessibility */}
      <AccessibilityMenu locale='es-ES' />

      {!isStarted && showLanguageSelector && (
        <LanguageSelector
          onClose={closeLanguageSelector}
          exit={exitLanguageSelector}
          supportedLangs={supportedLangs}
          locale={locale}
        />
      )}
      {isLoading ? (
        <div className='h-dvh flex flex-col justify-between items-center mx-16 pr-5 pl-5'>
          <Logo />
          <TailSpin
            color='#000'
            visible
            height='20'
            width='20'
            ariaLabel='tail-spin-loading'
            radius='1'
            wrapperStyle={{}}
            wrapperClass=''
          />
          <div />
        </div>
      ) : (
        <div className='h-dvh box-border flex flex-col justify-between items-center mx-[10%] sm:mx-[20%] small:mx-10 w-[300px] tablet:w-[500px] desktop:w-[50%]'>
          <Logo />
          {isFinish ? (
            <Finish
              finishData={finishData}
              goodbyeData={data.goodbye_text}
              canRestart={finishFromModal || e === "1"}
              onRestart={restartForm}
            />
          ) : error ? (
            <Error showLogo={false} />
          ) : (
            <>
              {!isStarted && (
                <Start
                  welcomeText={data.welcome_text}
                  onStart={changeStart}
                  locale={locale}
                  onTabClicked={openLanguageSelector}
                />
              )}
              {data.legal_text && (
                <ReactModal
                  isOpen={isModalOpen}
                  ariaHideApp={false}
                  className={`md:w-[500px] sm:w-full h-3/5 bg-black/[0.85] text-white fixed bottom-0 md:left-[calc(50%-250px)] rounded-t-[20px] px-12 py-10 flex flex-col justify-between modal ${font} modal-transition overflow-scroll`}
                >
                  <div className='flex-grow'>
                    <h2>{t("legal_text")}</h2>
                    <div className='small:max-h-[8rem] overflow-auto'>
                      {parse(data.legal_text)}
                    </div>
                  </div>
                  <div className=''>
                    <button
                      className='block generic-white-btn mb-5'
                      onClick={handleAcceptModal}
                      aria-label={t("close_accept")}
                      title={t("close_accept")}
                    >
                      {t("close_accept")}
                    </button>
                    <button
                      className='block generic-white-btn'
                      onClick={handleCloseModal}
                      aria-label={t("close")}
                      title={t("close")}
                    >
                      {t("close")}
                    </button>
                  </div>
                </ReactModal>
              )}
              {isStarted && (
                <div className='w-full flex-initial flex-grow flex flex-col gap-4'>
                  <div className='progress-container mb-6 small:mb-2'>
                    <div className='progress-bar' />
                  </div>
                  <Section
                    sections={data.form_sections}
                    specialData={specialData}
                    onAnswerChange={handleAnswerChange}
                    answers={answers}
                    saveForm={saveForm}
                    formId={data.id}
                    hashContinue={hashContinue}
                    store={store}
                    countryId={data.country_id}
                    multiLocationSection={
                      hasMultiLocations ? section ?? "1" : null
                    }
                  />
                </div>
              )}
            </>
          )}
          {isBusy && <OpacityLoader />}
        </div>
      )}
    </>
  );
}
