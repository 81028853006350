import "./App.css";
import "./ui/styles/Fonts.css";
import { Poll } from "./ui/pages/poll/Poll";
import { extractPayload, getFontFamily } from "./ui/utils/utils";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import ar from "./locales/ar.json";
import bg from "./locales/bg.json";
import bs from "./locales/bs.json";
import ca from "./locales/ca.json";
import cs from "./locales/cs.json";
import da from "./locales/da.json";
import de from "./locales/de.json";
import el from "./locales/el.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import eu from "./locales/eu.json";
import fi from "./locales/fi.json";
import fr from "./locales/fr.json";
import gl from "./locales/gl.json";
import hr from "./locales/hr.json";
import hu from "./locales/hu.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import ka from "./locales/ka.json";
import kk from "./locales/kk.json";
import ko from "./locales/ko.json";
import mk from "./locales/mk.json";
import nl from "./locales/nl.json";
import no from "./locales/no.json";
import pl from "./locales/pl.json";
import pt from "./locales/pt.json";
import ro from "./locales/ro.json";
import ru from "./locales/ru.json";
import sl from "./locales/sl.json";
import sk from "./locales/sk.json";
import sq from "./locales/sq.json";
import sr from "./locales/sr.json";
import sv from "./locales/sv.json";
import th from "./locales/th.json";
import tr from "./locales/tr.json";
import uk from "./locales/uk.json";
import zh from "./locales/zh.json";
import zhHK from "./locales/zh-HK.json";
import zhTW from "./locales/zh-TW.json";
import { Loader } from "ui/common/Loader";
import * as Constants from "./ui/utils/constants";
import { useEffect, useState } from "react";

export function App({ domainUrl, apiUrl, type }) {
  const queryParams = new URLSearchParams(window.location.search);
  const hashStore = queryParams.get("store");
  const hashContinue = queryParams.get("continue");
  const e = queryParams.get("e") || queryParams.get("is_point");
  const section = queryParams.get("section") || queryParams.get("section_id");
  const area = queryParams.get("area");
  const till = queryParams.get("till");
  const eid = queryParams.get("eid");
  const [initLocale, setInitLocale] = useState(navigator.language);
  const [fontFamily, setFontFamily] = useState(getFontFamily(initLocale));
  const [store, setStore] = useState(null);
  const [supportedLangs, setSupportedLangs] = useState({});
  // Check if language is Arabic to apply RTL
  const isRTL = initLocale === "ar" || initLocale.startsWith("ar-");

  useEffect(() => {
    if (hashStore == null && Constants.DEBUG) {
      setStore(extractPayload(Constants.DEBUG_STORE));
    } else if (hashStore != null) {
      setStore(extractPayload(hashStore));
    }

    /// Set language mark on html
    document.documentElement.lang = initLocale;
  }, [hashStore]);

  // Set HTML dir attribute based on language
  useEffect(() => {
    if (isRTL) {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [isRTL]);

  useEffect(() => {
    i18next.init({
      interpolation: { escapeValue: false },
      lng: initLocale,
      supportedLngs: [
        "ar",
        "bg",
        "bs",
        "ca",
        "cs",
        "de",
        "da",
        "el",
        "en",
        "es",
        "eu",
        "fi",
        "fr",
        "gl",
        "hr",
        "hu",
        "it",
        "ja",
        "ka",
        "kk",
        "ko",
        "mk",
        "nl",
        "no",
        "pl",
        "pt",
        "ro",
        "ru",
        "sk",
        "sl",
        "sq",
        "sr",
        "sv",
        "th",
        "tr",
        "uk",
        "zh",
        "zh-CN",
        "zh-HK",
        "zh-TW",
      ],
      fallbackLng: "en",
      resources: {
        ar: {
          global: ar,
        },
        bg: {
          global: bg, // Bulgarian
        },
        bs: {
          global: bs, // Bosnian
        },
        ca: {
          global: ca, // Catalan
        },
        cs: {
          global: cs, // Czech
        },
        da: {
          global: da,
        },
        de: {
          global: de, // German
        },
        el: {
          global: el, // Greek
        },
        en: {
          global: en, // English
        },
        es: {
          global: es, // Spanish
        },
        eu: {
          global: eu, // Basque
        },
        fi: {
          global: fi, // French
        },
        fr: {
          global: fr, // French
        },
        gl: {
          global: gl, // Galician
        },
        hr: {
          global: hr, // Croatian
        },
        hu: {
          global: hu, // Hungarian
        },
        it: {
          global: it, // Italian
        },
        ja: {
          global: ja, // Japan
        },
        ka: {
          global: ka, // Kazakh
        },
        kk: {
          global: kk, // Kazakh
        },
        ko: {
          global: ko, // Korean
        },
        mk: {
          global: mk, // Macedonian
        },
        nl: {
          global: nl, // Dutch
        },
        no: {
          global: no, // Norwegian
        },
        pl: {
          global: pl, // Polish
        },
        pt: {
          global: pt, // Portuguese
        },
        ro: {
          global: ro, // Romanian
        },
        ru: {
          global: ru, // Russian
        },
        sl: {
          global: sl, // Slovenian
        },
        sk: {
          global: sk, // Slovak
        },
        sq: {
          global: sq, // Albanian
        },
        sr: {
          global: sr, // Serbian
        },
        sv: {
          global: sv, // Swedish
        },
        th: {
          global: th, // Thai
        },
        tr: {
          global: tr, // Turkish
        },
        uk: {
          global: uk, // Ukranian
        },
        zh: {
          global: zh,
        },
        "zh-CN": {
          global: zh, // Chinese
        },
        "zh-HK": {
          global: zhHK,
        },
        "zh-TW": {
          global: zhTW,
        },
      },
    });

    const supportedLangs = {};
    for (const lang of Object.keys(i18next.services.resourceStore.data)) {
      const nameGenerator = new Intl.DisplayNames([lang], { type: "language" });
      supportedLangs[lang] = nameGenerator.of(lang);
    }
    setSupportedLangs(supportedLangs);
  }, []);

  const onSelectedLanguage = (lang) => {
    if (
      lang.includes("zh") ||
      initLocale.substring(0, 2) !== lang.substring(0, 2)
    ) {
      setInitLocale(lang);
      i18next.changeLanguage(lang).then((r) => console.log(r));
      setFontFamily(getFontFamily(lang));

      document.documentElement.lang = lang;
    }
  };

  const resetLocale = () => {
    onSelectedLanguage(navigator.language);
  };

  return (
    <I18nextProvider i18n={i18next}>
      <main className={`${fontFamily} ${isRTL ? "rtl" : ""}`}>
        {store != null ? (
          <Poll
            store={store}
            font={fontFamily}
            e={e}
            section={section}
            area={area}
            till={till}
            hashContinue={hashContinue}
            locale={initLocale}
            supportedLangs={supportedLangs}
            onSelectedLanguage={onSelectedLanguage}
            resetLocale={resetLocale}
            domainUrl={domainUrl}
            apiUrl={apiUrl}
            type={type}
            eid={eid}
          />
        ) : (
          <Loader />
        )}
      </main>
    </I18nextProvider>
  );
}
